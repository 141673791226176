import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from './../environments/environment';
import { LoggingService } from '../services/logging.service';
import { ErrorService } from '../services/error.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    const message: string = error instanceof HttpErrorResponse ?
      errorService.getServerErrorMessage(error) :
      errorService.getClientErrorMessage(error);
    notifier.showError(message);

    if (environment.production) {
      logger.logError(error);
    } else {
      console.error(error);
    }
  }
}
