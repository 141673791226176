import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpSuccessComponent } from '../app/sign-up-success/sign-up-success.component';
import { SignUpComponent } from '../app/sign-up/sign-up.component';
import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  { path: '', component: SignUpComponent },
  { path: 'success', component: SignUpSuccessComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
