import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';

import { environment } from './../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor() {
      Sentry.init({
        dsn: 'https://5e20d72a36324d8f9faeb63fc3a7d422@sentry.io/1779784',
        environment: environment.name
      });
    }

    logError(error: Error) {
        const eventId = Sentry.captureException(error);
    }
}
