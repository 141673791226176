import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    const fallbackErrorMessage = error.message;
    let errorMsg: any;
    if (
      /^4\d{2}$/.test(error.status.toString()) &&
      error.error.errors.length > 0
    ) {
      errorMsg = error.error.errors[0];
      if (errorMsg instanceof Object) {
        errorMsg = errorMsg.message;
      }
    }

    console.log(errorMsg);
    return navigator.onLine
      ? errorMsg || fallbackErrorMessage
      : "No Internet Connection";
  }
}
