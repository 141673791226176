import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  private applicantUrl = `${environment.apiUrl}/drive`;

  constructor(
    private http: HttpClient,
  ) { }

  submitApplicant(formData: FormData): Observable<any> {
    return this.http.post(this.applicantUrl, formData);
  }
}
