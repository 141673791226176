import * as phoneNormalizer from "phone";

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import { ApplicantService } from "src/services/applicant.service";
import { Router } from "@angular/router";
import { phoneValidator } from "src/shared/phone-validator.directive";

const BYTES_PER_MB = 1048576;

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  @ViewChild("resumeFileInput", { static: false }) resumeFileInput: any;
  @ViewChild("driversLicenseFileInput", { static: false })
  driversLicenseFileInput: any;


  resumeFile: File | null = null;
  resumeFileMaxSizeMb = 10;
  driversLicenseFile: File | null = null;
  driversLicenseFileMaxSizeMb = 10;

  emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  applicantForm = this.formBuilder.group({
    cdl_level: [null],
    email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
    felony: [false],
    first_name: [""],
    hazmat_endorsed: [false],
    hazmat_endorsement_issue_date: [""],
    hazmat_endorsement_ue_id: [""],
    last_name: [""],
    license_expiration_date: [""],
    license_number: [""],
    license_upload: [""],
    mechanical_experience: [""],
    phone: ["", [Validators.required, phoneValidator()]],
    resume_upload: [""],
    sex_offend: [false],
    skill_basic_electrical_knowledge: [false],
    skill_isolate_air_leaks: [false],
    skill_replace_relays: [false],
    skill_solder_and_crimp_wiring: [false],
    skill_tighten: [false],
    skill_use_meter: [false],
    skill_use_thread_tape: [false],
    skill_use_wrenches: [false],
    skill_wet_hose: [false],
    social_security: [""],
    zip_code: [
      "",
      [Validators.pattern(/(\d{5}([\-]\d{4})?)/), Validators.required],
    ],
  });

  constructor(
    private router: Router,
    private applicantService: ApplicantService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {}

  get firstName() {
    return this.applicantForm.get("first_name");
  }
  get lastName() {
    return this.applicantForm.get("last_name");
  }
  get phone() {
    return this.applicantForm.get("phone");
  }
  get email() {
    return this.applicantForm.get("email");
  }
  get zipCode() {
    return this.applicantForm.get("zip_code");
  }

  get isHazmatEndorsed() {
    return this.applicantForm.get("hazmat_endorsed").value;
  }
  get isCdl() {
    return this.applicantForm.get("cdl_level").value;
  }

  getErrorEmail() {
    return this.applicantForm.get("email").hasError("required")
      ? "Field is required"
      : this.applicantForm.get("email").hasError("pattern")
      ? "Not a valid emailaddress"
      : "";
  }

  onClickResumeFileInputButton() {
    this.onClickFileInputButton(this.resumeFileInput);
  }

  didUpdateHazmatEndorsement(status) {
    const { checked: isEndorsed } = status;
    const hazmatUidField = this.applicantForm.get("hazmat_endorsement_ue_id");
    const hazmatDateField = this.applicantForm.get(
      "hazmat_endorsement_issue_date"
    );

    if (isEndorsed) {
      hazmatDateField.setValidators([Validators.required]);
      hazmatUidField.setValidators([Validators.required]);
    } else {
      hazmatDateField.clearValidators();
      hazmatUidField.clearValidators();
    }
    hazmatDateField.updateValueAndValidity();
    hazmatUidField.updateValueAndValidity();
  }

  onChangeResumeFile(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file.size > this.resumeFileMaxSizeMb * BYTES_PER_MB) {
        alert(`Resume upload exceeds ${this.resumeFileMaxSizeMb}MB limit`)
        return;
      }
      this.resumeFile = file;
      this.applicantForm.get("resume_upload").setValue(file);
    }
  }

  onClickDriversLicenseFileInputButton() {
    this.onClickFileInputButton(this.driversLicenseFileInput);
  }

  onChangeDriversLicenseFileInput(event) {
    if (event.target.files && event.target.files.length) {
      let file: File = event.target.files[0];
      if (file.size > this.resumeFileMaxSizeMb * BYTES_PER_MB) {
        alert(`Drivers license upload exceeds ${this.resumeFileMaxSizeMb}MB limit`)
        return;
      }
      this.driversLicenseFile = file;
      this.applicantForm.get("license_upload").setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    Object.keys(this.applicantForm.controls).forEach((key) => {
      formData.append(key, this.applicantForm.controls[key].value);
    });

    const normalizedPhone = phoneNormalizer(this.applicantForm.value.phone);
    if (normalizedPhone.length > 0) {
      this.applicantForm.value.phone = normalizedPhone[0];
      formData.set("phone", this.phone.value);
    }

    this.applicantService.submitApplicant(formData).subscribe((applicant) => {
      this.router.navigate(["/success"]);
    });
  }

  private onClickFileInputButton(view: any): void {
    view.nativeElement.click();
  }
}
