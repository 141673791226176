import { AbstractControl, ValidatorFn } from '@angular/forms';

import * as phoneNormalizer from 'phone';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value) {
      const valid = phoneNormalizer(control.value).length === 0;
      return valid ? {validPhone: {value: control.value}} : null;
    }
  };
}
